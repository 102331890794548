import { NavLink } from 'react-router-dom';

import './NavBar.css';

const NavBarItem = ({ path, title }) => (
  <NavLink className={({ isActive }) => isActive ? 'nav-bar__link active' : 'nav-bar__link'} to={path}>
    {title}
  </NavLink>
);

const NavBar = () => {
  return (
    <div className='nav-bar'>
        <div className="nav-bar__menu nav-bar__menu--show">
            <div className='nav-bar__menu'>
              <NavBarItem path='/products-and-services' title='Products & Services' />
              <NavBarItem path='/platforms' title='Platforms' />
            </div>
        </div>
    </div>
  );
}

export default NavBar;