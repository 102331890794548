import { useRef } from 'react';

import Form from '../Form/Form';
import InputText from '../InputText/InputText';
import Button from '../Button/Button';
import './PlatformForm.css';

const PlatformForm = ({ 
  caption, 
  nameInitValue, 
  linkInitValue, 
  buttonText,
  status,
  setStatus,
  clearOnSubmit,
  onSubmit
}) => {
  const inName = useRef(null);
  const inLink = useRef(null);
  
  const handleSubmit = () => {
    const { value: name } = inName.current;
    const { value: link } = inLink.current;

    if (!name || !link) {
      setStatus({ error: true, msg: 'Please verify that the name and the link are filled before submitting...' });
      return;
    }
    
    setStatus({ error: false, msg: null });
    onSubmit(name, link);

    if (!status?.error && clearOnSubmit) {
      inName.current.value = '';
      inLink.current.value = '';
    }
  }

  return (
    <Form caption={caption} status={status}>
      <InputText
        ref={inName}
        label='Name'
        initValue={nameInitValue}
      />
      <InputText
        ref={inLink}
        label='Link'
        initValue={linkInitValue}
      />
      <Button onClick={handleSubmit}>{buttonText}</Button>
    </Form>
  );
}

export default PlatformForm;