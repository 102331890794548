import { 
  createBrowserRouter,
  RouterProvider,
  Navigate
 } from 'react-router-dom';

import RootLayout from './layouts/RootLayout';
import AddButtonLayout from './layouts/AddButtonLayout';
import ProductsScreen from './screens/ProductsScreen/ProductsScreen';
import AddProductScreen from './screens/AddProductScreen/AddProductScreen';
import EditProductScreen from './screens/EditProductScreen/EditProductScreen';
import PlatformsScreen from './screens/PlatformsScreen/PlatformsScreen';
import AddPlatformScreen from './screens/AddPlatformScreen/AddPlatformScreen';
import EditPlatformScreen from './screens/EditPlatformScreen/EditPlatformScreen';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '',
        element: <Navigate to='/products-and-services' />
      },
      {
        element: <AddButtonLayout path='products-and-services/add' />,
        children: [
          {
            path: 'products-and-services',
            element: <ProductsScreen />
          },
          {
            path: 'products-and-services/add',
            element: <AddProductScreen />
          },
          {
            path: 'products-and-services/edit/:id',
            element: <EditProductScreen />
          }
        ]
      },
      {
        element: <AddButtonLayout path='platforms/add' />,
        children: [
          {
            path: 'platforms',
            element: <PlatformsScreen />
          },
          {
            path: 'platforms/add',
            element: <AddPlatformScreen />
          },
          {
            path: 'platforms/edit/:id',
            element: <EditPlatformScreen />
          }
        ]
      }
    ]
  }
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
