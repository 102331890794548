import { useState, useEffect } from 'react';

import LoadingScreen from '../LoadingScreen/LoadingScreen';
import GridScreen from '../../components/GridScreen/GridScreen';
import ProductCard from '../../components/ProductCard/ProductCard';
import './ProductsScreen.css';

import { getProducts, deleteProduct } from '../../api/product.api';

const ProductsScreen = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleDelete = (id) => {
    setIsLoading(true);
    deleteProduct(id)
      .then(() => {
        setProducts(
          (prevProducts) => prevProducts.filter(({ id: _id }) => _id != id));
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getProducts()
      .then((products) => {
        setProducts(products);
        setIsLoading(false);
      });
  }, [setIsLoading, getProducts, setProducts]);

  if (isLoading)
    return <LoadingScreen />

  return (
    <GridScreen
      title='Products & Services Showcase'
      fallbackText={products.length === 0 ? 'There are no products at the moment' : null}
      itemHeight='auto'
    >
      {products.map(({ id, name, description }) => (
        <ProductCard 
          key={id} 
          id={id} 
          name={name} 
          description={description} 
          onDelete={handleDelete.bind(this, id)}
        />
      ))}
    </GridScreen>
  );
}

export default ProductsScreen;