import GridCard from '../GridCard/GridCard';
import './PlatformCard.css';

const PlatformCard = ({ id, name, link, onDelete }) => (
  <GridCard
    id={id}
    title={name}
    link={link}
    onDelete={onDelete}
  >
    <a className='platform-card__link' href={link} target='_blank'>{link}</a>
  </GridCard>
);

export default PlatformCard;