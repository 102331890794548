import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import LoadingScreen from '../LoadingScreen/LoadingScreen';
import ProductForm from '../../components/ProductForm/ProductForm';
import './EditProductScreen.css';

import { getProduct, updateProduct } from '../../api/product.api';
 
const EditProductScreen = () => {
  const [status, setStatus] = useState({ error: false, msg: '' });
  const [product, setProduct] = useState({ name: '', description: '' });
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  
  const handleSubmit = (name, description) => {
    setIsLoading(true);
    updateProduct(id, name, description)
      .then(() => {
        setProduct({ name, description });
        setStatus({ error: false, msg: 'Product updated successfully' });
        setIsLoading(false);
      })
      .catch((err) => {
        setStatus({ error: true, msg: err || 'Unknown Error' });
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getProduct(id)
      .then(({ name, description }) => {
        setProduct({ name, description });
        setIsLoading(false);
      })
      .catch((err) => {
        setStatus({ error: true, msg: err || 'Unknown Error' });
        setIsLoading(false);
      });
      
  }, [id, setProduct, setStatus, setIsLoading]);

  if (isLoading)
    return <LoadingScreen />

  return (
    <div className='edit-product-screen'>
      <ProductForm
        caption='Edit product'
        status={status}
        setStatus={setStatus}
        buttonText='Apply'
        nameInitValue={product.name}
        descriptionInitValue={product.description}
        clearOnSubmit={false}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default EditProductScreen;