import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { faAdd } from '@fortawesome/free-solid-svg-icons';

import FloatingIconButton from '../components/FloatingIconButton/FloatingIconButton';

const AddButtonLayout = ({ path }) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <FloatingIconButton 
        icon={faAdd} 
        onClick={() => navigate(path)}
      />
      <Outlet />
    </React.Fragment>
  );
}

export default AddButtonLayout;