import axios from 'axios';

const request = axios.create({
  baseURL: 'http://172.104.248.83:8080/platform'
});

export const getPlatform = (id) => (
  new Promise((resolve, reject) => {
    request.get(`/get/${id}`)
      .then(({ data }) => resolve(data.platform))
      .catch(({ response: res }) => reject(res.data.description))
  })
)

export const getPlatforms = () => (
  new Promise((resolve, reject) => (
    request.get('/get-all')
      .then(({ data }) => resolve(data.platforms))
      .catch(({ response: res } )=> reject(res.data.description))
  ))
)

export const addPlatform = (name, link) => (
  new Promise((resolve, reject) => (
    request.post('/add', { name, link })
      .then(() => resolve(null))
      .catch(({ response: res }) => reject(res.data.description))
  ))
)

export const updatePlatform = (id, name, link) => (
  new Promise((resolve, reject) => (
    request.post(`/edit/${id}`, { name, link })
      .then(() => resolve(null))
      .catch(({ response: res }) => reject(res.data.description))
  ))
)

export const deletePlatform = (id) => (
  new Promise((resolve, reject) => {
    request.post(`/delete/${id}`)
      .then(() => resolve(null))
      .catch(({ response: res }) => reject(res.data.description))
  })
)
