import './Form.css';

const Form = ({ children, caption, status }) => (
  <div className='card form'>
    <h3 className='form__caption'>{caption}</h3>
    {
        status?.msg
          ? (
            <p className={`message ${status.error ? 'message--error' : 'message--success'}`}>
              {status.msg}
            </p>
          )
          : undefined
      }
    {children}
  </div>
);

export default Form;