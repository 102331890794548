import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';

import './GridCard.css';

const GridCard = ({ children, id, title, onDelete }) => {
  const navigate = useNavigate();

  return (
    <div className='card grid-card'>
      <div className='grid-card__header'>
        <p className='grid-card__title'>{title}</p>
        <div className='grid-card__actions-container'>
          <FontAwesomeIcon 
            className='grid-card__icon' 
            icon={faPenToSquare}
            onClick={navigate.bind(this, `edit/${id}`)}
          />
          <FontAwesomeIcon 
            className='grid-card__icon' 
            icon={faTrash}
            color='var(--error)'
            onClick={onDelete}
          />
        </div>
      </div>
      {children}
    </div>
  );
}
export default GridCard;