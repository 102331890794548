import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './FloatingIconButton.css';

const FloatingIconButton = ({ icon, onClick  }) => (
  <div className='floating-icon-button' onClick={onClick}>
    <FontAwesomeIcon icon={icon} />
  </div>
);

export default FloatingIconButton;