import { useState, useEffect } from 'react';

import LoadingScreen from '../LoadingScreen/LoadingScreen';
import GridScreen from '../../components/GridScreen/GridScreen';
import PlatformCard from '../../components/PlatformCard/PlatformCard';
import './PlatformsScreen.css';

import { getPlatforms, deletePlatform } from '../../api/platform.api';

const PlatformsScreen = () => {
  const [platforms, setPlatforms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleDelete = (id) => {
    deletePlatform(id)
      .then(() => (
        setPlatforms((prevPlatforms) => prevPlatforms.filter(({ id: _id }) => _id !== id))
      ));
  }
 
  useEffect(() => {
    getPlatforms()
      .then((platforms) => {
        setPlatforms(platforms);
        setIsLoading(false);
      });
  }, [setIsLoading, setPlatforms]);

  if (isLoading)
    return <LoadingScreen />

  return (
    <GridScreen
      title='Platforms Showcase'
      fallbackText={platforms.length === 0 ? 'There are not platforms at the moment' : null}
      itemHeight={'auto'}
    >
      {platforms.map(({ id, name, link }) => (
        <PlatformCard
          key={id}
          id={id}
          name={name}
          link={link}
          onDelete={handleDelete.bind(this, id)}
        />
      ))}
    </GridScreen>
  );
}

export default PlatformsScreen;