import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ProductForm from '../../components/ProductForm/ProductForm';
import './AddProductScreen.css';

import { addProduct } from '../../api/product.api';

const AddProductScreen = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState({ erorr: false, msg: null });

  const handleSubmit = (name, description) => {
    addProduct(name, description)
      .then(() => {
        setStatus({ error: false, msg: 'Product added successfully' });
        navigate('/products-and-services');
      })
      .catch((err) => {
        setStatus({ error: true, msg: err || 'Unknown Error' })
      });
  }

  return (
    <div className='add-product-screen'>
        <ProductForm 
          caption='Add a new product'
          buttonText='Add'
          status={status}
          setStatus={setStatus}
          clearOnSubmit={true}
          onSubmit={handleSubmit}
        />
    </div>
  )
}

export default AddProductScreen;