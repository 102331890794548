import { useRef } from 'react';

import Form from '../Form/Form';
import InputText from '../InputText/InputText';
import TextArea from '../TextArea/TextArea';
import Button from '../Button/Button';
import './ProductForm.css';

const ProductForm = ({ 
  caption, 
  nameInitValue, 
  descriptionInitValue, 
  buttonText,
  status,
  setStatus,
  clearOnSubmit,
  onSubmit
}) => {
  const inNameRef = useRef(null);
  const inDescriptionRef = useRef(null);

  const handleSubmit = () => {
    const { value: name } = inNameRef.current;
    const { value: description } = inDescriptionRef.current;

    if (!name || !description) {
      setStatus({ 
        error: true, 
        msg: 'Please verify that the name and the description are filled before submitting...' 
      });
      return;
    }

    setStatus({ error: false, msg: null });
    onSubmit(name, description);
    
    if (!status?.error && clearOnSubmit) {
      inNameRef.current.value = '';
      inDescriptionRef.current.value = '';
    }
  }

  return (
    <Form status={status} caption={caption}>
      <InputText
        ref={inNameRef}
        label='Name'
        initValue={nameInitValue}
      />
      <TextArea
        ref={inDescriptionRef} 
        label='Description'
        initValue={descriptionInitValue}
      />
      <Button onClick={handleSubmit}>{buttonText}</Button>
    </Form>
  );
}

export default ProductForm;