import axios from 'axios';

const request = axios.create({
  baseURL: 'http://172.104.248.83:8080/product'
});

export const getProduct = (id) => (
  new Promise((resolve, reject) => {
    request.get(`/get/${id}`)
      .then(({ data }) => resolve(data.product))
      .catch(({ response: res }) => reject(res.data.description))
  })
)

export const getProducts = () => (
  new Promise((resolve, reject) => (
    request.get('/get-all')
      .then(({ data }) => resolve(data.products))
      .catch(({ response: res } )=> reject(res.data.description))
  ))
)

export const addProduct = (name, description) => (
  new Promise((resolve, reject) => (
    request.post('/add', { name, description })
      .then(() => resolve(null))
      .catch(({ response: res }) => reject(res.data.description))
  ))
)

export const updateProduct = (id, name, description) => (
  new Promise((resolve, reject) => (
    request.post(`/edit/${id}`, { name, description })
      .then(() => resolve(null))
      .catch(({ response: res }) => reject(res.data.description))
  ))
)

export const deleteProduct = (id) => (
  new Promise((resolve, reject) => {
    request.post(`/delete/${id}`)
      .then(() => resolve(null))
      .catch(({ response: res }) => reject(res.data.description))
  })
)
