import React, { useId } from 'react';

import './TextArea.css';

const TextArea = React.forwardRef(({ label, initValue, onChange }, ref) => {
  const id = useId();

  return (
    <div className='text-area'>
      <label className='text-area__label' htmlFor={id}>{label}</label>
      <textarea
        ref={ref}
        className='input text-area__input' 
        id={id} 
        rows='10'
        defaultValue={initValue}
        onChange={(e) => onChange && onChange(e.target.value)}
      >
      </textarea>
    </div>
  );
});

export default TextArea;