import React, { useId } from 'react';
import './InputText.css';

const InputText = React.forwardRef(({ label, initValue, onChange }, ref) => {
  const id = useId();

  return (
    <div className='input-text'>
      <label className='input-text__label' htmlFor={id}>{label}</label>
      <input
        ref={ref}
        className='input' 
        type='text' 
        id={id}
        onChange={(e) => onChange &&onChange(e.target.value)}
        defaultValue={initValue}
      />
    </div>
  );
});

export default InputText;