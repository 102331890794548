import Grid from '../Grid/Grid';
import './GridScreen.css';

const GridScreen = ({ children, title, fallbackText, itemHeight }) => (
  <div className='grid-screen'>
    <h1 className='grid-screen__title'>{title}</h1>
    {
      fallbackText
        ? <h3 className='grid-screen__fallback-text'>{fallbackText}</h3>
        : undefined
    }
    <Grid itemHeight={itemHeight}>
      {children}
    </Grid>
  </div>
);

export default GridScreen;