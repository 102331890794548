import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PlatformForm from '../../components/PlatformForm/PlatformForm';
import './AddPlatformScreen.css';

import { addPlatform } from '../../api/platform.api';

const AddPlatformScreen = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState({ error: false, msg: null });

  const handleSubmit = (name, link) => {
    addPlatform(name, link)
      .then(() => {
        setStatus({ error: false, msg: 'Platform added successfully' });
        navigate('/platforms');
      })
      .catch((err) => setStatus({ error: true, msg: err }));
  }

  return (
    <div className='add-platform-screen'>
      <PlatformForm
        caption='Add a new platform'
        buttonText='Add'
        status={status}
        setStatus={setStatus}
        clearOnSubmit={true}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default AddPlatformScreen;