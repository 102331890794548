import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import LoadingScreen from '../LoadingScreen/LoadingScreen';
import PlatformForm from '../../components/PlatformForm/PlatformForm';
import './EditPlatformScreen.css';

import { getPlatform, updatePlatform } from '../../api/platform.api';

const EditPlatformScreen = () => {
  const { id } = useParams();
  const [status, setStatus] = useState({ error: false, msg: null });
  const [platform, setPlatform] = useState({ name: '', link: '' });
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmit = (name, link) => {
    setIsLoading(true);
    updatePlatform(id, name, link)
      .then(() => {
        setPlatform({ name, link });
        setStatus({ error: false, msg: 'Platform updated successfully' });
        setIsLoading(false);
      })
      .catch((err) => {
        setStatus({ error: true, msg: err });
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getPlatform(id)
      .then(({ name, link }) => {
        setPlatform({ name, link });
        setIsLoading(false);
      })
      .catch((err) => {
        setStatus({ error: true, msg: err });
        setIsLoading(false);
      });
  }, [id, setPlatform, setStatus, setIsLoading]);

  if (isLoading)
    return <LoadingScreen />

  return (
    <div className='edit-platform-screen'>
      <PlatformForm
        caption='Edit platform'
        nameInitValue={platform.name}
        linkInitValue={platform.link}
        buttonText='Apply'
        status={status}
        setStatus={setStatus}
        clearOnSubmit={false}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default EditPlatformScreen;